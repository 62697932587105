export function formatDate(date) {
  let day = ("0" + date.getDate()).slice(-2)
  let month = ("0" + (date.getMonth() + 1)).slice(-2)
  let year = date.getFullYear()

  let hour = date.getHours()
  let minute = date.getMinutes()
  let second = date.getSeconds()
  let millisecond = date.getMilliseconds()

  return `${year}-${month}-${day} ${hour}:${minute}:${second}.${millisecond}`
}

export function generateId() {
  let lowercase = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
  let uppercase = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
  let numbers  =  ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]

  let characters = [lowercase, uppercase, numbers]

  var id = ""
  for (var i = 0; i < 10; i++) {
    let set = characters[Math.floor((Math.random() * characters.length))]

    id += set[Math.floor((Math.random() * set.length))]
  }

  return id
}

export function validateColorString(string) {
  if (string && [4, 5, 7].includes(string.length) && string[0] === "#" && hexValidator(string)) {
    return true
  }
}

export function hexValidator(string) {
  const validCharacters = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F"]
  for (let index in string) {
    if (index === "0") {
      continue
    }
    if (!validCharacters.includes(string[index].toUpperCase())) {
      return false
    }
  }
  return true
}

export function parseDate(date) {
  // omit date when Planned Event falls on same calendar day
  const today = new Date()
  date = new Date(date)
  if (today.getMonth() === date.getMonth() && today.getDate() === date.getDate()){
    return date.toLocaleString("default", {timeStyle: "short"}) + " Today"
  }
  else {
    return date.toLocaleString("default", {timeStyle: "short"}) + " " + date.toLocaleString("default", {dateStyle: "short"})
  }
}

export async function fetchWithTimeout(resource, options = {}) {
  // this allows the timeout to be set in the options object passed to fetch. otherwise it will default to 10 seconds
  const { timeout = 10000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);
  return response;
}

export function filterUnique(array) {
  return [...new Set(array)]
}

export function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function timeFromLocalStorage(time) {
  const timeString = localStorage.getItem(time)

  return timeString && new Date(timeString)
}

export function validateLocalStorage() {
  let result;
  let caughtError;

  try {
    const x = '__storage_test__';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
  }
  catch(error) {
    result = false
    caughtError = error
  }

  return {
    result: result,
    error: caughtError
  }
}

export function scrollElementToTop(element) {
  element.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"})
}

export function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function spaceAndCapitalize(string) {
  return string.split("_").map((word) => capitalize(word)).join(" ")
}
